const ExceptionCodes = {
  SUCCESS: 200,
  UNKNOW_ERROR: 500,
  DUPLICATED_ACCOUNT: 452,
  UNACTIVE_ACCOUNT: 453,
  BLOCKED_ACCOUNT: 454,
  BAD_CREDENTIALS: 455,
  UNKNOWN_ACCOUNT: 456,
  BAD_USERNAME: 457,
  BAD_PASSWORD: 458,
  WRONG_VERIFICATION_CODE: 459,
  CODE_EXPIRED: 460,
  CUSTOMER_NOT_FOUND: 570,
};

export default ExceptionCodes;
