/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disabled */

export default class TenantExtend {


  constructor (object?: any) {
  }

  postConstruct () {
  }
}
