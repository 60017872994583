<template>
  <!-- <nav> -->
    <!-- <router-link to="/">Home</router-link> | -->
    <!-- <router-link to="/about">About</router-link> -->

    <!-- <b-button variant="primary">lia</b-button> -->
  <!-- </nav> -->
  <router-view/>


  <!-- <div id="loader" v-if="$store.state.isLoading">
      <LoadingLogo></LoadingLogo>
    </div> -->
</template>

