<template>
  <b-container>
      <h2>Mes Contrats</h2>
      <div class="d-flex w-100 justify-content-end ">
        <div>
          <b-dropdown id="dropdown-1" :text="filterText" class="m-md-2" variant="primary" v-model="selectedOption">
            <b-dropdown-item  v-for="(item, index) in filterOptions" :key="index" @click="selectOption(item)" >{{item.label}}</b-dropdown-item>
          </b-dropdown>
        </div>
      </div>

      <div class="mt-3 rounded-2 overflow-hidden d-flex flex-column  bg-white px-3 py-2">
        <b-table head-variant="light" :fields="fields" :current-page="currentPage" id="my-table"
           :per-page="perPage" :tbody-transition-props="transProps"  sticky-header hover :items="dataFiltered" >
            
            <template #cell(photo)="data" >
                <img :src="data.item.photo" alt="" style="width: 13%; height: 13%;" class="img-fluid">
                <span class="m-5">{{ data.item.label }}</span>
            </template>

          <!-- <template #cell(id)="data" >
            <div class="w-50 d-flex">
              <b-button  class="mr-2">
                  Continuer
              </b-button>

              <b-button class="ms-3" variant="danger" :id="data.value"> 
                 Supprimer
              </b-button>
            </div>
          </template> -->
          <template #cell(id)="data">
            
              <router-link :to="'dashboard/contract/show/'+data.item.id"
              class="mr-2 btn btn-info" style="height: 40px; width: 40px; padding: 5px;" :id="data.item.uuid">
                <img style="object-fit: contain; width: 100%; height: 100%;" src="../../assets/icons/eyes.svg" alt="">
                <!-- {{ data.item }} -->
              </router-link>
<!-- {{ data.item.id }} -->
           

            <!-- <b-button class="ms-3" variant="danger" style="height: 40px; width: 40px; padding: 5px;"> 
              <img style="object-fit: contain; width: 100%; height: 100%;" src="../../assets/icons/delete.svg"
                    alt=""> 
            </b-button> -->
          </template>
        </b-table>
        <b-pagination class="align-self-end mt-3" v-model="currentPage" :total-rows="rows" :per-page="perPage"
                aria-controls="my-table">
        </b-pagination>
      </div>
      <div id="loader-container" v-if="isloading">
          <Loading></Loading>
      </div>
  </b-container>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import HelloWorld from '@/components/HelloWorld.vue'; // @ is an alias to /src
import Loading from '@/components/Loading.vue'; 
import { api } from '@/services/Api';
import { CONTRACT_STATUS, helper } from '@/services/Helper';

@Options({
  components: {
    HelloWorld,
    Loading
  },
  
})
export default class ContractView extends Vue {
  contrats: any[] = [];
  categories: any[] = [];
  dataFiltered: any[] = [];
  filterOptions: any[] = [
    {label:"Tous les contrats", data: "all"}
  ];
  filterText:any = "Tous les contrats";
  selectedOption:any = ""
  transProps= {
  name: 'flip-list'
  }
  isloading = false;
  fields = [
      { key: 'photo', label: 'Contrat' },
      { key: 'insurer', label: 'Assureur' },
      { key: 'duration', label: 'Période' },
      { key: 'id', label: '' },
  ]
  perPage = 10
  currentPage = 1

  // items:any[] = [
  //   {
  //     photo:"https://master.wia.ci/uploads/63a8186c-c6ba-4c97-ab82-1a75ae5d7d98.svg",
  //     insurer:"ATLANTA",
  //     duration:"20 Sept 23 - 19 Déc 23",
  //     status:"",
  //     label:"Auto",
  //     id:"1ee57e2e-e5d1-6b8c-8219-00505646c51f",
  //   },
  //   {
  //     photo:"https://master.wia.ci/uploads/6eeed4f3-d2f7-4569-b180-4ae408c189bb.svg",
  //     insurer:"ATLANTA",
  //     duration:"20 Sept 23 - 19 Déc 23",
  //     status:"",
  //     label:"Voyage",
  //     id:"1ee57e2e-e5d1-6b8c-8219-00505646c51f",
  //   },
  //   {
  //     photo:"https://master.wia.ci/uploads/63a8186c-c6ba-4c97-ab82-1a75ae5d7d98.svg",
  //     insurer:"ATLANTA",
  //     duration:"20 Sept 23 - 19 Déc 23",
  //     status:"",
  //     label:"Auto",
  //     id:"1ee57e2e-e5d1-6b8c-8219-00505646c51f",
  //   },
  //   {
  //     photo:"https://master.wia.ci/uploads/6eeed4f3-d2f7-4569-b180-4ae408c189bb.svg",
  //     insurer:"ATLANTA",
  //     duration:"20 Sept 23 - 19 Déc 23",
  //     status:"",
  //     label:"Voyage",
  //     id:"1ee57e2e-e5d1-6b8c-8219-00505646c51f",
  //   },
  //   {
  //     photo:"https://master.wia.ci/uploads/63a8186c-c6ba-4c97-ab82-1a75ae5d7d98.svg",
  //     insurer:"ATLANTA",
  //     duration:"20 Sept 23 - 19 Déc 23",
  //     status:"",
  //     label:"Auto",
  //     id:"1ee57e2e-e5d1-6b8c-8219-00505646c51f",
  //   },
  //   {
  //     photo:"https://master.wia.ci/uploads/6eeed4f3-d2f7-4569-b180-4ae408c189bb.svg",
  //     insurer:"ATLANTA",
  //     duration:"20 Sept 23 - 19 Déc 23",
  //     status:"",
  //     label:"Voyage",
  //     id:"1ee57e2e-e5d1-6b8c-8219-00505646c51f",
  //   },
  // ];

  async mounted() {
    await this.loadContracts();
    console.log(this.rows());
  }
  selectOption(item:any){
    this.filtering(item)
  }
  rows() {
    return this.dataFiltered.length
  }
  
  async loadContracts(){
    this.isloading = true;
    await this.loadBranchCategories();
    try {
      const res = await api.get(api.core, "selfcare/contracts");
      //console.log(res.data)
      if (res && res.data && res.data.contracts) {
        let branch:any = []
        res.data.contracts.forEach((cont: any) => {
          // console.log(cont)
          if (!branch.includes(cont.branch.label)) {
            this.filterOptions.push({label:cont.branch.label, data:(cont.branch.label).toLowerCase()})
            branch.push(cont.branch.label)
          }
          // console.log(this.filterOptions)
          const data:any = {}
          data.photo = process.env.VUE_APP_MASTER_URL+"uploads/"+cont.branch.photo
          data.insurer = cont.insurer.label
          data.duration = helper.readable(cont.startAt, 'dMy') +" - "+helper.readable(cont.expireAt, 'dMy')
          data.status = cont.status
          data.label = cont.branch.label
          data.id = cont.uuid

          // console.log(data)
          // console.log(cont)
          this.contrats.push(data);
          this.dataFiltered = [...this.contrats]
        })
        // this.items.forEach((cont: any) => {
        //   // console.log(cont)
        //   if (!branch.includes(cont.label)) {
        //     this.filterOptions.push({label:cont.label, data:(cont.label).toLowerCase()})
        //     branch.push(cont.label)
        //   }
        //   // console.log(this.filterOptions)
        //   const data:any = {}
        //   data.photo = cont.photo
        //   data.insurer = cont.insurer
        //   data.duration = cont.duration
        //   data.status = cont.status
        //   data.label = cont.label
        //   data.id = cont.uuid

        //   // console.log(data)
        //   // console.log(cont)
        //   this.contrats.push(data);
        //   this.dataFiltered = this.contrats
        // })
      }
    } catch (e) {
      console.log(e);
    }
    this.isloading = false;
  }
  async loadBranchCategories(){
    try {
      const res = await api.get(api.core, "api/branch/list");
      //console.log(res.data)
      if (res && res.data && res.data.categories) {
        res.data.categories.forEach((cat: any) => {
          this.categories.push(cat);
        })
      }
    } catch (e) {
      console.log(e);
    }
  }

  filtering(filter: any){
    let results:any[] = [...this.contrats];
    if (filter) {
      results = results.filter((c) => {
        return c.label === filter.label;
      });
    }
    
    console.log(results);
    if (results.length <= 0) {
      this.dataFiltered = [...this.contrats]
    } else {
      this.dataFiltered = []
      results.forEach((f:any) => {
        this.dataFiltered.push(f);
      });
    }
    this.filterText = filter.label
  };

}
</script>
<style scoped>
#loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

</style>